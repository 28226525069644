















import { Component, Prop, Mixins } from "vue-property-decorator";
import CompanyContactCard from "@/components/company-detail/CompanyContactCard.vue";
import CompanyBusinessCard from "@/components/company-detail/CompanyBusinessCard.vue";
import DetailLayoutWrapper from "@/components/detail-page/DetailLayoutWrapper.vue";
import ContactAttachment from "@/components/contact-attachment/ContactAttachment.vue";
import DialogMixin from "@/mixins/DialogMixin";
import Company from "@/entity/Company";

@Component({
  components: {
    CompanyContactCard,
    CompanyBusinessCard,
    DetailLayoutWrapper,
    ContactAttachment,
  },
})
export default class CompanyDetailView extends Mixins(DialogMixin) {
  @Prop({ required: true }) company!: Company;
}
