












































import { Vue, Component, Prop } from "vue-property-decorator";
import Contact from "@/entity/Contact";
import SectionTitle from "@/components/detail-page/SectionTitle.vue";
import SectionHeading from "@/components/detail-page/SectionHeading.vue";
import ItemList from "@/components/detail-page/ItemList.vue";
import { State } from "vuex-class";
import { ReferenceItem } from "@/services/types";

@Component({
  components: {
    SectionTitle,
    ItemList,
    SectionHeading,
  },
})
export default class CompanyContactCard extends Vue {
  @Prop({ required: true }) company!: Contact;

  @State("regions", { namespace: "selectOptions" }) regions!: ReferenceItem[];

  get regionName(): string | null {
    const region = this.regions.find(
      (region) => region.id === this.company.regionId
    );
    return region ? region.name : null;
  }

  get buyerType(): string {
    return this.company.buyerType?.name ?? this.$tc("na", 1);
  }
}
